<script setup lang="ts">
import { useRouter } from 'vue-router/composables'
import ui from '/~/core/ui'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { Activity, useActivity } from '/~/composables/activity'
import ActivityDetailsRefund from './activity-details-refund.pure.vue'

withDefaults(
  defineProps<{
    activity?: Activity
  }>(),
  {}
)

const router = useRouter()
const { routeState } = useActivity()

function goBack() {
  router.replace({ name: 'activity', ...routeState.value })
}
</script>

<template>
  <div class="flex flex-col">
    <div v-if="activity && !ui.mobile" class="mb-4 mt-6">
      <base-button look="link" @click="goBack">
        <base-icon svg="chevron-left" size="xl" class="-ml-1.5" />
        <span class="text-base">Back</span>
      </base-button>
    </div>

    <div
      class="flex flex-grow flex-col border-eonx-neutral-400 bg-white sm:rounded-lg sm:border"
    >
      <activity-details-refund :activity="activity" :loading="false" />
    </div>
  </div>
</template>
